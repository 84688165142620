import Cookies from 'js-cookie';

const themes = ['tarantula', 'cigar'];
const contrasts = ['false', 'true'];

export function getTheme() {
  const key = parseInt(Cookies.get('theme')) || 0;
  Cookies.set('theme', key, { sameSite: 'strict' });
  return themes[key];
}

export function rotateTheme() {
  var key = parseInt(Cookies.get('theme')) || 0;
  key = (key + 1 >= themes.length) ? 0 : (key + 1);
  Cookies.set('theme', key, { sameSite: 'strict' });
  return themes[key];
}

export function getContrast() {
  var key = parseInt(Cookies.get('contrast')) || 0;
  Cookies.set('contrast', key, { sameSite: 'strict' });
  return contrasts[key];
}

export function toggleContrast() {
  var key = parseInt(Cookies.get('contrast')) || 0;
  key = (key == 0) ? 1 : 0;
  Cookies.set('contrast', key, { sameSite: 'strict' });
  return contrasts[key];
}

// vim: set ft=javascript:
